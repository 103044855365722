import MenuBookIcon from '@mui/icons-material/MenuBook';
import DevotionList from "./DevotionList";
import DevotionCreate from "./DevotionCreate";
import DevotionEdit from "./DevotionEdit";

const resource = {
  list: DevotionList,
  create: DevotionCreate,
  edit: DevotionEdit,
  icon: MenuBookIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

import { useState, useEffect, Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AppFooter from "./modules/views/AppFooter";
import AuthService from "../services/auth.service";
import VolunteerService from "../services/volunteer.service";
import EventBus from "../utilities/EventBus";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
} from "@mui/material";

const Devotions = () => {
  const [redirect, setRedirect] = useState("");
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [currentDevotion, setCurrentDevotion] = useState("")
  const [devotions, setDevotions] = useState([
    {
      id: "0",
      title: "default",
      devotion: "",
    },
  ]);
  const [retrievedData, setRetrievedData] = useState(false);
  const [content, setContent] = useState("");
  const location = useLocation().pathname;
  
  if (location === "/weeklydevotions") {
    if (!retrievedData) {
      const currentUser = AuthService.getAuthenticated();
      if (!currentUser) {
        setRedirect("/login");
      }
      VolunteerService.getDevotions().then(
        (response) => {
          setRetrievedData(true);
          setDevotions(response.data);
        },
        (error) => {
          console.error("erred retrieving devotions");
          setContent(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
          );

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
            setRedirect("/");
          }
        },
      );
    }
  }

  if (redirect !== "") {
    return <Navigate to={redirect} />;
  } else if (!retrievedData) {
    return (<>Loading...</>)
  }

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="week-select-label">Week</InputLabel>
            <Select
              labelId="week-select-label"
              id="week"
              required
              displayEmpty
              value={selectedWeek}
              name="week"
              label="Week"
              //@ts-ignore
              onChange={(e) => setSelectedWeek(e.target.value)}
            >
              {devotions.map(({ title }, index) => (
                <MenuItem key={title} value={index}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div dangerouslySetInnerHTML={{__html:devotions[selectedWeek].devotion}} />
    <AppFooter/>
    </Fragment>
  );
};

export default Devotions;


import {
  Datagrid,
  List,
  TextField,
  useListContext
} from "react-admin";
import { createElement } from "react"
import { Devotion } from "../../types";

const DevotionGrid = () => {
  const { data } = useListContext<Devotion>(); 
  if (data) 
    data.map((record) => {
    const cleanText = record.devotion.replace(/<\/?[^>]+(>|$)/g, "");
    record.cleanText = cleanText
  }) 
  return (
<Datagrid
          optimized
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <TextField source="title" />
          <TextField source="cleanText" />
        </Datagrid>
  )
}
const DevotionList = () => {
  
  return (
    <List
      perPage={25}
    >
        <DevotionGrid/>
    </List>
  );
};

export default DevotionList;

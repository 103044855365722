/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { useState } from "react";
import { Navigate } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { IconButton, Modal, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from "../../../services/user.service";
import PlayerService from "../../../services/player.service";
import { useNotify } from "react-admin";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = () => {
  return Yup.object({
    subject: Yup.string().required("Subject cannot be blank"),
    message: Yup.string().required("Message cannot be blank"),
  });
};

//@ts-ignore
function RegisteredPlayersCard({ title, description, data, shadow }) {
  const [playerIds, setPlayerIds] = useState([""]);
  const [photo, setPhoto] = useState(data.photo);
  const [redirect, setRedirect] = useState("");
  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const PayNow = (id: string[]) => {
    setPlayerIds(id);
    setRedirect("/checkout");
  };

  const notify = useNotify();
  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setSent(true);

      await UserService.sendMessage(
        values.subject,
        values.message,
        data.teamId,
      ).then(
        //@ts-ignore
        (response) => {
          setSent(false);
          setSuccessOpen(true);
          resetForm();
          setTimeout(() => {
            setSuccessOpen(false);
            setOpen(false);
          }, 3000);
        },
        //@ts-ignore
        (error) => {
          if (error.response.status === 409) {
            setSent(false);
          }
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        },
      );
    },
  });

  const removePhoto = () => {
    PlayerService.removePhoto(data.id);
    setPhoto(false);
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    setSent(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  if (redirect !== "") {
    return <Navigate to={redirect} state={{ players: playerIds }} />;
  }
  return (
    <Card sx={{ height: "100%", boxShadow: 1, width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </Typography>
        {data.fullyPaid ? (
          <Button variant="contained" color="success">
            Paid
          </Button>
        ) : data.fullGroup ? (
          <Button
            variant="contained"
            color="warning"
          >
            On Waitlist
          </Button>
        ) : (
        <Button
          variant="contained"
          color="error"
          onClick={() => PayNow([data.id])}
        >
          Pay Now
        </Button>
        )}
      </Box>
      <Box p={2}>
        <Box sx={{ opacity: 0.3 }}>
          <Divider />
        </Box>
        <Box>
          {photo ? (
            <Box sx={{ maxHeight: "140px" }}>
              <Box
                component="img"
                src={photo}
                crossOrigin="anonymous"
                sx={{ maxWidth: "120px" }}
              />
              <IconButton onClick={removePhoto} sx={{ mb: 15, ml: 7 }}>
                <CancelIcon fontSize="large" />
              </IconButton>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop={2}
            >
              <Typography>
                No photo on record
                <Tooltip
                  sx={{ marginLeft: "-10px", marginTop: "-5px" }}
                  title="Photos are used in the camps to help coaches place a face to the name. They are optional but encouraged"
                >
                  <IconButton>
                    <HelpIcon sx={{ height: "14px" }} />
                  </IconButton>
                </Tooltip>
              </Typography>

              <Button
                variant="contained"
                component="label"
                size="small"
                startIcon={<CloudUploadIcon />}
                sx={{ marginBottom: "10px", textAlign: "center" }}
              >
                Upload
                <input
                  type="file"
                  accept="image/*"
                  name="photo"
                  hidden
                  onChange={(event) => {
                    if (!event.target.files) return;
                    const formData = new FormData();
                    //@ts-ignore
                    formData.append("photo", event.target.files[0]);
                    formData.append("firstName", data.firstName);
                    formData.append("lastName", data.lastName);
                    formData.append("playerId", data.id);

                    PlayerService.upload(formData).then(
                      (response) => {
                        setPhoto(response.url);
                      },
                      (error) => {
                        notify(
                          typeof error === "string"
                            ? error
                            : typeof error === "undefined" || !error.message
                              ? "error uploading photo"
                              : error.message,
                          {
                            type: "error",
                            messageArgs: {
                              _:
                                typeof error === "string"
                                  ? error
                                  : error && error.message
                                    ? "Error uploading photo"
                                    : undefined,
                            },
                          },
                        );
                      },
                    );
                  }}
                />
              </Button>
            </Box>
          )}
          <Box display="flex" pr={2}>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Team: &nbsp;
            </Typography>
            <Typography variant="button" fontWeight="regular" color="text">
              &nbsp;{data.team}
            </Typography>
          </Box>

          <Box display="flex" pr={2}>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Coach: &nbsp;
            </Typography>
            <Typography variant="button" fontWeight="regular" color="text">
              &nbsp;{data.coach}
            </Typography>
          </Box>
          <Box display="flex" pr={2}>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Practice Time: &nbsp;
            </Typography>
            <Typography variant="button" fontWeight="regular" color="text">
              &nbsp;{data.practiceTime}
            </Typography>
          </Box>
          <Box display="flex" pr={2}>
            <Typography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Jersey Number: &nbsp;
            </Typography>
            <Typography variant="button" fontWeight="regular" color="text">
              &nbsp;{data.number}
            </Typography>
          </Box>
          {data.team && (
          <Button size="small" variant="contained" onClick={() => handleOpen()}>
            Contact {data.firstName}'s Coach
          </Button>
          )}

          {/* <Grid item xs={6} md={5} alignItems="center">
              {data.fullyPaid ?
                <Button variant="contained" fullWidth color="success">Paid</Button>
                :
                <Button variant="contained" fullWidth color="error" onClick={() => PayNow([data.id])}>Pay Now</Button>
              }
            </Grid> */}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box sx={style}>
            <TextField
              id="outlined-basic"
              label="Subject"
              name="subject"
              variant="outlined"
              fullWidth
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={formik.touched.subject && formik.errors.subject}
              required
            />

            <TextField
              id="message"
              name="message"
              label="Message"
              fullWidth
              multiline
              rows={4}
              placeholder="Message to coach"
              variant="filled"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
              required
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ marginRight: "5px", minWidth: "125px" }}
            >
              {sent ? "Sending..." : "Send"}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ marginRight: "5px", minWidth: "125px" }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Modal>
      <Modal
        open={successOpen}
        onClose={handleSuccessClose}
        aria-labelledby="success-message"
        aria-describedby="message-success"
      >
        <Box sx={style}>
          <Typography
            id="success-message"
            color="success"
            align="center"
            variant="h6"
          >
            Message Sent!
          </Typography>
        </Box>
      </Modal>
    </Card>
  );
}

// Setting default props for the RegisteredPlayersCard
RegisteredPlayersCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the RegisteredPlayersCard
RegisteredPlayersCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  data: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
};

export default RegisteredPlayersCard;

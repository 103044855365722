import {
    Datagrid,
    List,
    TextField,
    ReferenceField,
    NumberField,
    BooleanField,
} from "react-admin";

const EventList = () => {
    return (
        <List
            sort={{ field: "end", order: "DESC" }}
            perPage={25}
        // aside={<EventListAside />}
        >
            <Datagrid
                optimized
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                
                <TextField source="name" label="Team Name" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="lineUpNumber" />
                <TextField source="uniformTopSize" />
                <TextField source="uniformBottomSize" />
                <TextField source="uniformUnderGarmentSize" />
            </Datagrid>
        </List>
    );
};

export default EventList;

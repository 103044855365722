import {
    Datagrid,
    DateField,
    DateInput,
    List,
    TextField,
    SearchInput,
    ReferenceField,
    NumberField,
    BooleanField,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

import MobileGrid from "./MobileGrid";
import EventListAside from "./PlayerListAside";

const EventFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
];

const EventList = () => {
    const isXsmall = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down("sm"),
    );
    const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
    return (
        <List
            filters={isSmall ? EventFilters : undefined}
            sort={{ field: "end", order: "DESC" }}
            perPage={25}
            aside={<EventListAside />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <TextField source="lineUpNumber" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <TextField source="nickname" />
                    <NumberField source="age" />
                    <NumberField source="grade" />
                    <BooleanField source="fullyPaid" />
                    <ReferenceField source="parentId" reference="users" >
                        <TextField source="fullName" />
                        </ReferenceField>
                    <ReferenceField source="teamId" reference="teams" >
                        <TextField source="name" />
                        </ReferenceField>
                    {/* <ReferenceField source="teamId" reference="teams" /> */}
                    <BooleanField source="hasSiblings" />
                    <TextField source="category" />
                    <TextField source="orderId" />
                    <TextField source="gender" />
                </Datagrid>
            )}
        </List>
    );
};

export default EventList;

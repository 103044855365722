import {
  Edit,
  TextInput,
  SimpleForm,
} from "react-admin";

const TeamEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};

export default TeamEdit;

import { useState, useEffect, Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdminService from "../services/admin.service";
import AuthService from "../services/auth.service";

interface Tier {
  title: string;
  subheader?: string;
  description: string[];
  buttonText: string;
  buttonVariant: "outlined" | "contained";
  link?: string;
  function?: any
}

const step1Tiers: Tier[] = [
  {
    title: "Practice Times",
    subheader: "Step 1",
    description: ["Set up practice times/days"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "practicetimes",
  },
  {
    title: "Game Times",
    subheader: "Step 2",
    description: ["What days/times will games be"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "gametimes",
  },
  {
    title: "Age Groups",
    subheader: "Step 3",
    description: ["Configure the desired age brackets"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "teamgroups",
  },
];

const step2Tiers: Tier[] = [
  {
    title: "Coaches",
    subheader: "Step 1",
    description: ["Assign Coaches to an age group"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "coachassignment",
  },
  {
    title: "Assistant Coaches",
    subheader: "Step 2",
    description: ["Assign assistant coaches to their head coach"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "asstcoachassignment",
  },
  {
    title: "Practices",
    subheader: "Step 3",
    description: ["Assign teams their practice days"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "practiceassignment",
  },
  {
    title: "Games",
    subheader: "Step 4",
    description: ["Create the game schedule"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "gameassignment",
  },
  {
    title: "Devotions",
    subheader: "Step 5",
    description: ["Create the camp and practice devotions"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "devotions",
  },
  {
    title: "Publish Coach assignments",
    subheader: "Step 6",
    description: ["Notifies all coaches of assignments", "Make sure you are ready", "and click this only once!"],
    buttonText: "Publish",
    buttonVariant: "contained",
    function: AdminService.publishCoaches
  },
  {
    title: "Players",
    subheader: "Step 7",
    description: ["Assign players to their teams"],
    buttonText: "Let's Go",
    buttonVariant: "contained",
    link: "playerassignment"
  },
  {
    title: "Publish Players",
    subheader: "Step 8",
    description: ["Notify all parents of team assignments"],
    buttonText: "Publish",
    buttonVariant: "contained",
    function: AdminService.publishPlayers
  },
  {
    title: "Order Uniforms",
    subheader: "Step 9",
    description: ["Export data into excel and forward to uniform distributer"],
    buttonText: "Sizing",
    buttonVariant: "contained",
    link: "sizing"
  },
];

export default function SeasonBoard(): JSX.Element {

  const [checked, setChecked] = useState(false);
  const [year, setYear] = useState(0);
  const [seasonAlreadyExists, setSeasonAlreadyExists] = useState(false);
  const [seasonCreated, setSeasonCreated] = useState(false);
  const [redirect, setRedirect] = useState("");
  const location = useLocation().pathname;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (location === "/adminseasonboard") {
      const currentUser = AuthService.getAuthenticated();
      const isAdmin = AuthService.isAdmin();

      if (!currentUser) {
        setRedirect("/login");
      } else if (!isAdmin) {
        setRedirect("/");
      }
    }
  }, []);

  if (redirect !== "") {
    return <Navigate to={redirect} />;
  }
  return (
    <Fragment>
      <CssBaseline />
      <Container
        maxWidth="sm"
        component="main"
        sx={{ padding: "0, 24, 0, 24" }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Season Configuration
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Follow these steps to set up and configure a new season
        </Typography>
      </Container>
      <Container
        maxWidth="md"
        component="main"
        sx={{
          border: 1,
          borderRadius: 1,
          paddingBottom: "10px",
          marginBottom: "25px",
        }}
      >
        <Typography variant="h4">
          Phase 1: Create a new season
        </Typography>
        <Card>
          <CardHeader
            title="Clicking this to configure a new season for the current calendar year"
            subheader="By doing this, all new player and volunteer registrations will be associated with the new season "
            titleTypographyProps={{ align: "center" }}
            subheaderTypographyProps={{ align: "center" }}
          // className={classes.cardHeader}
          />
          <FormGroup sx={{ alignItems: "center" }}>
            <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label="Check this to set up a season for the next calendar year" />
          </FormGroup>
          {/* <CardContent>
                    <Typography variant="subtitle1" align="center" key="newSeasonCreation">
                      Start here to create a new season
                    </Typography>
                </CardContent> */}
          <CardActions>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={async () => {
                const response = await AdminService.createNewSeason(checked)
                setYear(response.data.year)
                if (response.status === 200) {
                  setSeasonAlreadyExists(false)
                  setSeasonCreated(true)
                }
                else if (response.status === 409){
                  setSeasonAlreadyExists(true)
                  setSeasonCreated(false)
                }
              }}
            >
              Create New Season
            </Button>
          </CardActions>
          <CardContent>
            <Typography variant="h6" align="center" key="newSeasonCreation" color="#00e676" display={seasonCreated ? "block" : "none"}>
              {year} Season Created!
            </Typography>
            <Typography variant="h6" align="center" key="seasonAlreadyExists" color="#FF0000" display={seasonAlreadyExists ? "block" : "none"}>
              {year} has already been created!
            </Typography>
          </CardContent>
        </Card>
      </Container>
      <Container
        maxWidth="md"
        component="main"
        sx={{
          border: 1,
          borderRadius: 1,
          paddingBottom: "10px",
          marginBottom: "25px",
        }}
      >
        <Typography variant="h4">
          Phase 2: Schedule/Team Configuration
        </Typography>
        <Grid container spacing={5} alignItems="flex-end">
          {step1Tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                // className={classes.cardHeader}
                />
                <CardContent>
                  {tier.description.map((line) => (
                    <Typography variant="subtitle1" align="center" key={line}>
                      {line}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    color="primary"
                    variant={tier.buttonVariant}
                    href={tier.link}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container
        maxWidth="md"
        component="main"
        sx={{
          border: 1,
          borderRadius: 1,
          paddingBottom: "10px",
          marginBottom: "25px",
        }}
      >
        <Typography variant="h4">Phase 3: Assignments</Typography>
        <Grid container spacing={5} alignItems="flex-end">
          {step2Tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                // className={classes.cardHeader}
                />
                <CardContent>
                  {tier.description.map((line) => (
                    <Typography variant="subtitle1" align="center" key={line}>
                      {line}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    color="primary"
                    variant={tier.buttonVariant}
                    href={tier.link ? tier.link : undefined }
                    onClick={() => tier.function ? tier.function() : undefined }
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Fragment>
  );
}

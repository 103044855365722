/// <reference types="vite-plugin-svgr/client" />

import * as React from "react";
import { NavLink, matchPath, useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import {
  UserMenu,
  useUserMenu,
  Logout,
  LoadingIndicator,
  ToggleThemeButton,
  useNotify,
} from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import LoginIcon from "@mui/icons-material/Login";
import Logo from "./images/uplift_logo.svg?react";
import authProvider from "./services/auth.service";
// import { ThemeSwapper } from './themes/ThemeSwapper';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Divider from "@mui/material/Divider";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import Badge from "@mui/material/Badge";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationService from "./services/notification.service";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { MessageNotification } from "./types";

// It's important to pass the ref to allow Material UI to manage the keyboard navigation
const SettingsMenuItem = React.forwardRef((props, ref) => {
  // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
  const { onClose } = useUserMenu();
  const navigate = useNavigate();
  const onClick = () => {
    onClose;
    navigate("/profile");
  };
  return (
    //@ts-ignore
    <MenuItem
      onClick={onClick}
      ref={ref}
      // It's important to pass the props to allow Material UI to manage the keyboard navigation
      {...props}
    >
      <ListItemIcon>
        <SettingsIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Profile</ListItemText>
    </MenuItem>
  );
});

const publicPages = [
  { text: "Event Calendar", link: "eventcalendar" },
  { text: "Game Schedule", link: "gameschedule" },
  { text: "Contact", link: "contact" },
  { text: "Share a Photo!", link: "upload" },
  // { text: 'Team Photos', link: "teamphotos" }
];
const userPages = ["Registrations"];
const coachPages = ["Rotation", "LineUp", "Team Communication", "Weekly Devotions", "Camp Ratings"];
const memberPages = ["Volunteer"];
const refPages = ["Ref Schedule"]

const ResponsiveAppBar = () => {
  const authenticated = authProvider.getAuthenticated();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  const [openCoachMenu, setOpenCoachMenu] = React.useState(false);
  const [openRefereeMenu, setOpenRefereeMenu] = React.useState(false);
  const [openNotificationMenu, setOpenNotificationMenu] = React.useState(false);
  const [notifications, setNotifications] = React.useState<
    MessageNotification[]
  >([]);
  const anchorRefUserMenu = React.useRef<HTMLButtonElement>(null);
  const anchorRefCoachMenu = React.useRef<HTMLButtonElement>(null);
  const anchorRefRefereeMenu = React.useRef<HTMLButtonElement>(null);
  const anchorRefNotificationMenu = React.useRef<HTMLButtonElement>(null);
  const isReferee = authProvider.isReferee();
  const isCoach = authProvider.isCoach();
  const isMember = authProvider.isMember();
  const notify = useNotify();
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleUserMenuToggle = () => {
    setOpenUserMenu((prevOpen) => !prevOpen);
  };

  const handleUserMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRefUserMenu.current &&
      anchorRefUserMenu.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenUserMenu(false);
  };

  function handleUserMenuListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenUserMenu(false);
    } else if (event.key === "Escape") {
      setOpenUserMenu(false);
    }
  }

  const handleCoachMenuToggle = () => {
    setOpenCoachMenu((prevOpen) => !prevOpen);
  };

  const handleCoachMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRefCoachMenu.current &&
      anchorRefCoachMenu.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenCoachMenu(false);
  };

  function handleCoachMenuListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenCoachMenu(false);
    } else if (event.key === "Escape") {
      setOpenCoachMenu(false);
    }
  }

  function handleRefereeMenuListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenRefereeMenu(false);
    } else if (event.key === "Escape") {
      setOpenRefereeMenu(false);
    }
  }

  const handleRefereeMenuToggle = () => {
    setOpenRefereeMenu((prevOpen) => !prevOpen);
  };

  const handleRefereeMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRefRefereeMenu.current &&
      anchorRefRefereeMenu.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenRefereeMenu(false);
  };

  const handleNotificationMenuToggle = () => {
    setOpenNotificationMenu((prevOpen) => !prevOpen);
  };

  const handleNotificationMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRefNotificationMenu.current &&
      anchorRefNotificationMenu.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenNotificationMenu(false);
  };

  function handleNotificationMenuListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenNotificationMenu(false);
    } else if (event.key === "Escape") {
      setOpenNotificationMenu(false);
    }
  }

  const displayNotifications = () => {
    //TODO After notification open, mark all notifs as read. Will need to update service and controller to be able to call it here
    const cards = notifications.map((notif: any) => {
      return (
        <Card sx={{ width: "250px", marginBottom: "5px" }}>
          <Box padding={1} display="flex">
            <Typography fontWeight={"bold"}>From: {notif.type}</Typography>
          </Box>
          <Box padding={1} display="flex">
            <Typography>{notif.message}</Typography>
          </Box>
        </Card>
      );
    });
    return cards;
  };

  const UserIcon = () => (
    <AccountCircleIcon
      sx={{
        height: 40,
        width: 40,
      }}
    />
  );

  React.useEffect(() => {
    if (authenticated) {
      NotificationService.getNotifications(authenticated.id).then(
        (response) => {
          setNotifications(response.data.notifications);
          // notify(
          //   <Alert severity="error">
          //       Count: {response.data.count}
          //   </Alert>
          // )
        },
        (error) => {
          console.error("Error retrieving notification count");
          notify(
            <Alert severity="error">Error retrieving notifications</Alert>,
          );
        },
      );
    }
  }, []);

  return (
    <AppBar position="sticky" color="secondary">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Link href="/">
            <SvgIcon
              component={Logo}
              inheritViewBox
              sx={{
                width: "50px",
                height: "50px",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem key="about" onClick={handleCloseNavMenu}>
                <Link href="/about" underline="none" color="primary">
                  <Typography textAlign="center">What is Uplift?</Typography>
                </Link>
              </MenuItem>

              {publicPages.map((page) => (
                <MenuItem key={page.link} onClick={handleCloseNavMenu}>
                  <Link href={"/" + page.link} underline="none" color="primary">
                    <Typography textAlign="center">{page.text}</Typography>
                  </Link>
                </MenuItem>
              ))}
              <Divider sx={{ my: 0.5 }} />
              {isCoach &&
                coachPages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Link
                      href={"/" + page.toLowerCase().replace(/\s/g, "")}
                      underline="none"
                      color="primary"
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              <Divider sx={{ my: 0.5 }} />
              {isReferee &&
                refPages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Link
                      href={"/" + page.toLowerCase().replace(/\s/g, "")}
                      underline="none"
                      color="primary"
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              <Divider sx={{ my: 0.5 }} />
              {isMember && (
                <MenuItem key="volunteer" onClick={handleCloseNavMenu}>
                  <Link
                    href="/volunteersignup"
                    underline="none"
                    color="primary"
                  >
                    <Button variant="contained" color="success">
                      Volunteer
                    </Button>
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Link href="/">
            <SvgIcon
              component={Logo}
              inheritViewBox
              sx={{
                width: "50px",
                height: "50px",
                display: { xs: "flex", md: "none" },
                mr: 1,
              }}
            />
          </Link>

          <Box
            sx={{
              justifyContent: "center",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <Button
              key="about"
              onClick={handleCloseNavMenu}
              sx={{ display: "block" }}
            >
              <Link href="/about" sx={{ color: "primary" }} underline="hover">
                <Typography textAlign="center">What is Uplift?</Typography>
              </Link>
            </Button>
            {publicPages.map((page) => (
              <Button
                key={page.text}
                onClick={handleCloseNavMenu}
                sx={{ display: "block" }}
              >
                <Link
                  href={"/" + page.link}
                  sx={{ color: "primary" }}
                  underline="hover"
                >
                  <Typography textAlign="center">{page.text}</Typography>
                </Link>
              </Button>
            ))}

            {authenticated && (
              <>
                <Button
                  ref={anchorRefUserMenu}
                  id="composition-button"
                  aria-controls={openUserMenu ? "composition-menu" : undefined}
                  aria-expanded={openUserMenu ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleUserMenuToggle}
                  endIcon={<KeyboardArrowDownIcon />}
                  variant="contained"
                  size="small"
                  sx={{ marginY: "15px", marginX: "7px" }}
                >
                  <Typography>Parent Tools</Typography>
                </Button>
                <Popper
                  open={openUserMenu}
                  anchorEl={anchorRefUserMenu.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleUserMenuClose}>
                          <MenuList
                            autoFocusItem={openUserMenu}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleUserMenuListKeyDown}
                          >
                            {userPages.map((page) => (
                              <MenuItem
                                key={page}
                                onClick={handleUserMenuClose}
                              >
                                <Link
                                  href={
                                    "/" + page.toLowerCase().replace(/\s/g, "")
                                  }
                                  underline="none"
                                  color="primary"
                                >
                                  <Typography textAlign="center">
                                    {page}
                                  </Typography>
                                </Link>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}

            {isCoach && (
              <>
                <Button
                  ref={anchorRefCoachMenu}
                  id="composition-button"
                  aria-controls={openCoachMenu ? "composition-menu" : undefined}
                  aria-expanded={openCoachMenu ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleCoachMenuToggle}
                  endIcon={<KeyboardArrowDownIcon />}
                  variant="contained"
                  size="small"
                  sx={{ marginY: "15px", marginX: "7px" }}
                >
                  <Typography>Coach Tools</Typography>
                </Button>
                <Popper
                  open={openCoachMenu}
                  anchorEl={anchorRefCoachMenu.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCoachMenuClose}>
                          <MenuList
                            autoFocusItem={openCoachMenu}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleCoachMenuListKeyDown}
                          >
                            {coachPages.map((page) => (
                              <MenuItem
                                key={page}
                                onClick={handleCoachMenuClose}
                              >
                                <Link
                                  href={
                                    "/" + page.toLowerCase().replace(/\s/g, "")
                                  }
                                  underline="none"
                                  color="primary"
                                >
                                  <Typography textAlign="center">
                                    {page}
                                  </Typography>
                                </Link>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
            {isReferee && (
              <>
                <Button
                  ref={anchorRefRefereeMenu}
                  id="composition-button"
                  aria-controls={openRefereeMenu ? "composition-menu" : undefined}
                  aria-expanded={openRefereeMenu ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleRefereeMenuToggle}
                  endIcon={<KeyboardArrowDownIcon />}
                  variant="contained"
                  size="small"
                  sx={{ marginY: "15px", marginX: "7px" }}
                >
                  <Typography>Ref Tools</Typography>
                </Button>
                <Popper
                  open={openRefereeMenu}
                  anchorEl={anchorRefRefereeMenu.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleRefereeMenuClose}>
                          <MenuList
                            autoFocusItem={openRefereeMenu}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleRefereeMenuListKeyDown}
                          >
                            {refPages.map((page) => (
                              <MenuItem
                                key={page}
                                onClick={handleRefereeMenuClose}
                              >
                                <Link
                                  href={
                                    "/" + page.toLowerCase().replace(/\s/g, "")
                                  }
                                  underline="none"
                                  color="primary"
                                >
                                  <Typography textAlign="center">
                                    {page}
                                  </Typography>
                                </Link>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </Box>
          <Box sx={{ flexGrow: 0, display: "flex" }}>
            {isMember && (
              <MenuItem key="volunteer">
                <Link href="/volunteersignup" underline="none" color="primary">
                  <Button variant="contained" size="small" color="success">
                    Volunteer
                  </Button>
                </Link>
              </MenuItem>
            )}
            <ToggleThemeButton />

            {/* <ThemeSwapper /> */}
            {authenticated !== false && (
              <>
                {/* <LoadingIndicator
                  sx={{
                    '& .RaLoadingIndicator-loader': {
                      marginTop: 2,
                    },
                  }}
                /> */}
                <Box
                  sx={{
                    height: 56,
                    width: 40,
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Notifications">
                    <Badge
                      badgeContent={notifications ? notifications.length : null}
                      color="primary"
                      overlap="circular"
                    >
                      <IconButton
                        ref={anchorRefNotificationMenu}
                        onClick={handleNotificationMenuToggle}
                        sx={{
                          height: 40,
                          width: 40,
                          alignItems: "center",
                        }}
                      >
                        <CircleNotificationsIcon
                          color="action"
                          sx={{
                            height: 40,
                            width: 40,
                            alignItems: "center",
                          }}
                        />
                      </IconButton>
                      <Popper
                        open={openNotificationMenu}
                        anchorEl={anchorRefNotificationMenu.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom-start"
                                  ? "left top"
                                  : "left bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener
                                onClickAway={handleNotificationMenuClose}
                              >
                                <Grid
                                  onKeyDown={handleNotificationMenuListKeyDown}
                                >
                                  {displayNotifications()}
                                </Grid>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Badge>
                  </Tooltip>
                </Box>
                <UserMenu icon={<UserIcon />}>
                  <SettingsMenuItem />
                  <Logout />
                </UserMenu>
              </>
            )}
            {authenticated === false && (
              <>
                <Button
                  key="Login"
                  variant="text"
                  color="secondary"
                  href="/login"
                  // sx={{ my: 2, display: 'block' }}
                >
                  Login
                </Button>
                <Button
                  key="SignUp"
                  variant="contained"
                  color="secondary"
                  href="/signup"
                  sx={{ marginLeft: "8px" }}
                >
                  SignUp
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;

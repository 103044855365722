import {
  Admin,
  ListGuesser,
  Resource,
  defaultTheme,
  useGetIdentity,
  localStorageStore,
  StoreContextProvider,
  CustomRoutes,
  useStore,
  ShowGuesser,
  EditGuesser,
} from "react-admin";
import { useState, useEffect } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { themes, ThemeName } from "./themes/themes";

import { authProvider } from "./services/auth.service";
import { Dashboard } from "./dashboard";
import dataProvider from "./newDataProvider";
import asstCoachAssignment from "./adminTools/asstCoachAssignment";
import coachAssignment from "./adminTools/coachAssignment";
import practiceAssignment from "./adminTools/practiceAssignment";
import playerAssignment from "./adminTools/playerAssignment";
import gameAssignment from "./adminTools/gameAssignment";
import users from "./adminTools/users";
import players from "./adminTools/players";
import teams from "./adminTools/teams";
import gameTimes from "./adminTools/gameTimes";
import events from "./adminTools/events";
import communications from "./adminTools/communications";
import teamGroups from "./adminTools/teamGroups";
import practiceTimes from "./adminTools/practiceTimes";
import volunteers from "./adminTools/volunteers";
import expenses from "./adminTools/expenses";
import Segments from "./adminTools/segments/Segments";
import sizing from "./adminTools/SizingChart"
import devotions from "./adminTools/devotions"

import { Layout } from "./layout";
import { AdminLayout } from "./adminLayout";
import EventBus from "./utilities/EventBus";
import {
  Home,
  AboutUs,
  ContactUs,
  SignUp,
  Terms,
  ForgotPassword,
  ChangePassword,
  Login,
  Privacy,
  Rotation,
  TeamCommunication,
  Devotions,
  Profile,
  Schedule,
  RefSchedule,
  Coding,
  Register,
  LineUp,
  Unsubscribe,
  SeasonCalendar,
  VolunteerSignUp,
  AdminRotation,
  PhotoUpload,
  AdminPlayerRandomizer,
  Registrations,
  AdminCommunication,
  AdminSeasonBoard,
  TeamPhotos,
  CampRatings,
} from "./customPages/";
import { CheckoutForm, Return } from "./customPages/Payment";

const store = localStorageStore(undefined, "Uplift");

const App = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [useCoachView, setUseCoachView] = useState(false);
  const [themeName] = useStore<ThemeName>("themeName", "soft");
  const lightTheme = themes.find((theme) => theme.name === themeName)?.light;
  const darkTheme = themes.find((theme) => theme.name === themeName)?.dark;
  const logOut = function () {
    const param = "test";
    authProvider.logout(param);
    EventBus.dispatch("refresh");
  };
  const refreshHeader = function () {
    const isAdmin = authProvider.isAdmin();
    setIsAdmin(isAdmin);
  };
  const coachView = function () {
    setUseCoachView(!useCoachView);
  };
  useEffect(() => {
    if (!isAdmin) {
      const isAdmin = authProvider.isAdmin();
      setIsAdmin(isAdmin);
    }
    EventBus.on("logout", logOut);
    EventBus.on("refresh", refreshHeader);
    EventBus.on("coachView", coachView);

    return () => {
      EventBus.remove("logout", logOut);
      EventBus.remove("refresh", refreshHeader);
      EventBus.remove("coachView", coachView);
    };
  }, [isAdmin]);
  return (
    <BrowserRouter>
      <StoreContextProvider value={store}>
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          basename="/"
          store={localStorageStore(undefined, "Uplift")}
          layout={isAdmin && !useCoachView ? AdminLayout : Layout}
          dashboard={Dashboard}
          loginPage={Login}
          lightTheme={lightTheme}
          darkTheme={darkTheme}
          defaultTheme="light"
        >
          <CustomRoutes>
            <Route path="/home" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/tos" element={<Terms />} />
            <Route path="/rotation" element={<Rotation />} />
            <Route path="/teamcommunication" element={<TeamCommunication />} />
            <Route path="/weeklydevotions" element={<Devotions />} />
            <Route path="/segments" element={<Segments />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/gameschedule" element={<Schedule />} />
            <Route path="/refschedule" element={<RefSchedule />} />
            <Route path="/coding" element={<Coding />} />
            <Route path="/register" element={<Register />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/return" element={<Return />} />
            <Route path="/lineup" element={<LineUp />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/eventcalendar" element={<SeasonCalendar />} />
            <Route path="/volunteersignup" element={<VolunteerSignUp />} />
            <Route path="/adminrotation" element={<AdminRotation />} />
            <Route path="/upload" element={<PhotoUpload />} />
            <Route path="/campratings" element={<CampRatings />} />
            <Route
              path="/playerrandomizer"
              element={<AdminPlayerRandomizer />}
            />
            <Route path="/registrations" element={<Registrations />} />
            <Route
              path="/admincommunication"
              element={<AdminCommunication />}
            />
            <Route path="/adminseasonboard" element={<AdminSeasonBoard />} />
            <Route path="/teamphotos" element={<TeamPhotos />} />
          </CustomRoutes>
          <Resource name="users" {...users} />
          <Resource name="events" {...events} />
          <Resource name="communications" {...communications} />
          <Resource name="asstcoachassignment" {...asstCoachAssignment} />
          <Resource name="coachassignment" {...coachAssignment} />
          <Resource name="practiceassignment" {...practiceAssignment} />
          <Resource name="gameassignment" {...gameAssignment} />
          <Resource name="playerassignment" {...playerAssignment} />
          <Resource name="players" {...players} />
          <Resource name="teams" {...teams} />
          <Resource name="gametimes" {...gameTimes} />
          <Resource name="volunteers" {...volunteers} />
          <Resource name="teamgroups" {...teamGroups} />
          <Resource name="practicetimes" {...practiceTimes} />
          <Resource name="expenses" {...expenses} />
          <Resource name="sizing" {...sizing} />
          <Resource name="devotions" {...devotions} />
        </Admin>
      </StoreContextProvider>
    </BrowserRouter>
  );
};

export default App;

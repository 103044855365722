import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import AppForm from "./modules/views/AppForm";
import FormButton from "./modules/form/FormButton";
import AuthService from "../services/auth.service";

const passwordSpecialCharacters = /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/;

const emailValidationSchema = () => {
  return Yup.object({
    email: Yup.string()
      .email("This is not a valid email")
      .required("Password is required!"),
  });
};
const codeValidationSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, "Code should be 6 digits")
    .max(6, "Code should be 6 digits")
    .required("Code required"),
});
const passwordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Password should be of minimum 8 characters length")
    .matches(passwordSpecialCharacters, "Needs to include a special character")
    .required("Password is required!"),
  newPasswordConfirmation: Yup.string()
    .required()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    }),
});

const ForgotPassword = () => {
  const [redirect, setRedirect] = useState("");
  const [sent, setSent] = useState(false);
  const [validatedCode, setValidatedCode] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation().pathname;

  useEffect(() => {}, [userEmail]);

  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      setSent(true);
      await AuthService.forgotPassword(values.email).then(
        //@ts-ignore
        (response) => {
          setUserEmail(values.email);
          setSent(false);
          setErrorMessage("")
        },
        //@ts-ignore
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            setErrorMessage(resMessage)
          setSent(false);
        },
      );
    },
  });

  const codeFormik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeValidationSchema,
    onSubmit: async (values) => {
      setSent(true);
      await AuthService.confirmCode(userEmail, values.code).then(
        //@ts-ignore
        (response) => {
          setValidatedCode(values.code);
          setSent(false);
          setErrorMessage("")
        },
        //@ts-ignore
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            setErrorMessage(resMessage)
          setSent(false);
        },
      );
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async (values) => {
      setSent(true);
      await AuthService.changePassword(
        userEmail,
        values.newPassword,
        undefined,
        validatedCode,
      ).then(
        //@ts-ignore
        (response) => {
          setRedirect("/login");
          setSent(false);
          setErrorMessage("")
        },
        //@ts-ignore
        (error) => {
          const resMessage = 
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setErrorMessage(resMessage)
          setSent(false);
        },
      );
    },
  });

  if (redirect) {
    return <Navigate to={redirect} />;
  }
  return (
    <>
      {userEmail === "" ? (
        <>
          <AppForm>
            <>
              <Typography
                variant="h3"
                gutterBottom
                marked="center"
                align="center"
              >
                Reset Password
              </Typography>
            </>
            <form onSubmit={emailFormik.handleSubmit} noValidate>
              <Box sx={{ mt: 6 }}>
                <TextField
                  disabled={sent}
                  fullWidth
                  label="Email"
                  name="email"
                  id="email"
                  type="email"
                  value={emailFormik.values.email}
                  onChange={emailFormik.handleChange}
                  onBlur={emailFormik.handleBlur}
                  error={
                    emailFormik.touched.email &&
                    Boolean(emailFormik.errors.email)
                  }
                  helperText={
                    emailFormik.touched.email && emailFormik.errors.email
                  }
                  required
                />
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={sent}
                  color="secondary"
                  fullWidth
                >
                  {sent ? "In progress…" : "Reset Password"}
                </FormButton>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={sent}
                  color="secondary"
                  fullWidth
                  href="/login"
                >
                  Cancel
                </FormButton>
              </Box>
            </form>
          </AppForm>
          <AppFooter />
        </>
      ) : validatedCode === "" ? (
        <>
          <AppForm>
            <>
              <Typography
                variant="h3"
                gutterBottom
                marked="center"
                align="center"
              >
                Reset Password
              </Typography>
            </>
            <form onSubmit={codeFormik.handleSubmit} noValidate>
              <Box sx={{ mt: 6 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  marked="center"
                  align="center"
                >
                  If the email provided is linked to an account you will receive
                  an email with a temporary code
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  marked="center"
                  align="center">
                * If you need assistance, please 
                email uplift@tannehillvalley.org
                </Typography>
                <TextField
                  disabled={sent}
                  fullWidth
                  label="Code"
                  name="code"
                  id="code"
                  value={codeFormik.values.code}
                  onChange={codeFormik.handleChange}
                  onBlur={codeFormik.handleBlur}
                  error={
                    codeFormik.touched.code && Boolean(codeFormik.errors.code)
                  }
                  helperText={codeFormik.touched.code && codeFormik.errors.code}
                  required
                />
                {errorMessage != "" && (
                <Typography color="error">
                  {errorMessage}
                </Typography>
                )}
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={sent}
                  color="secondary"
                  fullWidth
                >
                  {sent ? "In progress…" : "Confirm Code"}
                </FormButton>
              </Box>
            </form>
          </AppForm>
          <AppFooter />
        </>
      ) : (
        <>
          <AppForm>
            <>
              <Typography
                variant="h3"
                gutterBottom
                marked="center"
                align="center"
              >
                Reset Password
              </Typography>
            </>
            <form onSubmit={passwordFormik.handleSubmit} noValidate>
              <Box sx={{ mt: 6 }}>
                <TextField
                  disabled={sent}
                  fullWidth
                  label="New Password"
                  name="newPassword"
                  id="newPassword"
                  type="password"
                  value={passwordFormik.values.newPassword}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={
                    passwordFormik.touched.newPassword &&
                    Boolean(passwordFormik.errors.newPassword)
                  }
                  helperText={
                    passwordFormik.touched.newPassword &&
                    passwordFormik.errors.newPassword
                  }
                  required
                />
                <TextField
                  disabled={sent}
                  fullWidth
                  type="password"
                  label="Confirm Password"
                  name="newPasswordConfirmation"
                  id="newPasswordConfirmation"
                  value={passwordFormik.values.newPasswordConfirmation}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={
                    passwordFormik.touched.newPasswordConfirmation &&
                    Boolean(passwordFormik.errors.newPasswordConfirmation)
                  }
                  helperText={
                    passwordFormik.touched.newPasswordConfirmation &&
                    passwordFormik.errors.newPasswordConfirmation
                  }
                  required
                />
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={sent}
                  color="secondary"
                  fullWidth
                >
                  {sent ? "In progress…" : "Set New Password"}
                </FormButton>
              </Box>
            </form>
          </AppForm>
          <AppFooter />
        </>
      )}
    </>
  );
};

export default ForgotPassword;

import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Box, Typography } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text"

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any;
  if (!values.devotion) {
    errors.start = "ra.validation.required";
  }
  if (!values.title) {
    errors.start = "ra.validation.required";
  }
  return errors;
};

const DevotionCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm
        defaultValues={{
          devotion: "",
          title: ""
        }}
        validate={validateForm}
      >
        <Typography variant="h6" gutterBottom>
          Devotions
        </Typography>
        <TextInput type="text" source="title" isRequired fullWidth />
          <RichTextInput source="devotion" fullWidth isRequired />
      </SimpleForm>
    </Create>
  );
};

export default DevotionCreate;

import * as React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
} from "react-admin";
import { Grid, Box, Typography } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text"


import { validateForm } from "./DevotionCreate";

const DevotionEdit = () => {
  return (
    <Edit>
      <SimpleForm validate={validateForm}>
        <Typography variant="h6" gutterBottom>
          Devotions
        </Typography>
        <TextInput type="text" source="title" isRequired fullWidth />
        <RichTextInput source="devotion" fullWidth isRequired />

      </SimpleForm>
    </Edit>
  );
};

export default DevotionEdit;

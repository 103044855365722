import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SizeList from "./SizeList";

const resource = {
  list: SizeList,
  icon: CalendarMonthIcon,
  recordRepresentation: (record: any) => `${record.title}`,
};

export default resource;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Ref from "../images/2024_ref_schedule.jpg";

const Schedule = () => {
  return (
    <Box component="section" bgcolor="background">
      {/* <Container
                sx={{
                    mt: 10,
                    mb: 15,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            > */}
      <Typography variant="h4" component="h2" sx={{ mb: 4 }}> 
        Ref Schedule
      </Typography>
      {/* <Typography>
        Schedule will be posted soon!
      </Typography> */}
      <Box component="img" src={Ref} alt="referee schedule" />
      {/* </Container> */}
    </Box>
  );
};

export default Schedule;
